import { HtmlAstPath } from "@angular/compiler";
import { AfterViewInit, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
//import { ConsoleReporter } from "jasmine";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Boleto } from "../models/Boleto";
import { BoletoValidar } from "../models/BoletoValidar";
import { Campus } from "../models/Campus";
import { Comprador } from "../models/Comprador";
import { Pais } from "../models/Pais";
import { MyHttpGetService } from "../services/http.services.token";
import { LocalstorageService } from "../services/localstorage.service";
import { SessionstorageService } from "../services/sessionstorage.service";
import { TimerService } from "../timer/timer.service";
import { DatosCompradorService } from "./services/datos-comprador.service";
import * as $ from 'jquery';
import { Canales } from "../models/canales";
import { SeguimientoService } from "../services/seguimiento.service";

var tab, img, imge, nombre, apellidos, apellido_paterno, apellido_materno, egresado, campus, egresadoslct: any;
@Component({
  selector: "app-datos-comprador",
  templateUrl: "./datos-comprador.component.html",
  styleUrls: ["./datos-comprador.component.scss"],
})
export class DatosCompradorComponent implements OnInit, AfterViewInit {
  ///
  public flagCampus: boolean;
  public flagPlaceCampus: boolean;
  ///
  formDatosComprador: FormGroup;
  public tipos_pago: number;
  formDatosColaborador: FormGroup;
  formDatosCampus: FormGroup;
  public cont = 0;
  public contador: number = 0;
  public boletos_generados: Array<Boleto> = [];
  public boletos_recomendados: Array<Boleto> = [];
  public boletos: Array<Boleto> = [];
  public boletocheck: Boleto;
  public total_compra: number = 0;
  public mensajeError: string = "";
  public valida: boolean = false;
  public messageAlert: string;
  private messageAlert1: string;
  private typeAlert: string;
  public Colonias: any = [];
  public obtenerDatos: Array<Comprador> = [];
  public aceptar_terminos: boolean = false;
  public rotular_todos: boolean = false;
  public ocultar_cp: boolean = false;
  public Datos: any = [];
  public Compradores: any = [];
  public nuevoUsuario: boolean = false;
  public flagSelect = false;
  //BOLETO VALIDAR
  boletoValida: BoletoValidar = new BoletoValidar();
  public boletoVal: any = [];
  public flagBolVal = false;

  especificaCanalInvalid: boolean = false;
  //FIN BOLETO VALIDAR
  public Info: Array<Comprador> = [
    {
      pk1: 0,
      nombre: "",
      apellidos: "",
      apellido_materno: "",
      apellido_paterno: "",
      telefono_fijo: "",
      telefono_movil: "",
      correo_electronico: "",
      correo_alternativo: "",
      correo_tarjetahabiente: "",
      calle: "",
      numero: "",
      pais: "",
      codigo_postal: "",
      colonia: "",
      estado: "",
      municipio: "",
      campus: "",
      egresado: "",
      pkcanal: null,
      especifica_canal: "",
      dia:  "",
      mes:  "",
      anio: "",
    },
  ];

  public InfoEgr: any = [
    {
      pk1: "",
      folio3: "",
      folio2: "",
      folio1: "",
      pais: "",
      telefono_celular: "",
      telefono_casa: "",
      colonia: "",
      calle: "",
      cp: "",
      alcaldia_municipo: "",
      ciudad: "",
      estado_provincia: "",
      nombre_empresa: "",
      ocupacion: "",
      apellidos: "",
      segundo_nombre: "",
      nombre_pila: "",
      email_original: "",
      generacion: "",
      email: "",
      fecha_nacimiento: "",
      nombre_completo: "",
      carrera: "",
      campus: "",
      matricula: ""
    }


  ];


  public validaDatos: Array<string> = [];
  public UrlAnahuac: string;
  public colaborador_voluntarioC: boolean = false;
  public egresadoC: boolean = false;
  public selecciona_colaboradorC: boolean = false;
  public selecciona_egresadoC: string = "0";
  public correocolaborador: any;
  public IDColab: string;
  public egresado: string;
  public resultIDcolab: string;
  public nombreColab: string;
  public nombreColab2: string;
  public intentosColab: number = 3;
  public paises: Observable<Pais[]>;
  public canales: any = [];
  public campusall: Observable<Campus[]>;
  public flagCP = false;


  /** Cambios Luis Aguilar WECODE */
  ShowPopCampania: boolean = false;
  //@ViewChild('autoShownModal', { static: false }) autoShownModal: ModalDirective;
  // formDatosCompradorCampania: FormGroup;
  // public InfoCompradorCampania: any = {
  //   correo: ''
  // };
  public flagVentaXInt = false;
  /** END Cambios Luis Aguilar WECODE */


  dias: number[] = [];
  meses = [
    { name: 'Enero', value: 1 },
    { name: 'Febrero', value: 2 },
    { name: 'Marzo', value: 3 },
    { name: 'Abril', value: 4 },
    { name: 'Mayo', value: 5 },
    { name: 'Junio', value: 6 },
    { name: 'Julio', value: 7 },
    { name: 'Agosto', value: 8 },
    { name: 'Septiembre', value: 9 },
    { name: 'Octubre', value: 10 },
    { name: 'Noviembre', value: 11 },
    { name: 'Diciembre', value: 12 }
  ];
  anios: number[] = [];
  fechaNacimiento = { dia: null, mes: null, anio: null };


  constructor(
    private _local: LocalstorageService,
    private _session: SessionstorageService,
    public st: TimerService,
    private fb: FormBuilder,
    private router: Router,
    private _DCompradorService: DatosCompradorService,
    private myGetService: MyHttpGetService,
    private route: ActivatedRoute,
    private readonly _seguimiento: SeguimientoService
  ) {

    /*test */
    //console.log(this._local.getCampaingnData());
    /*end tes*/
    this.flagCampus = false;
    this.flagPlaceCampus = false;
    var lsGenerados = this._local.getBoletosGenerados();
    var lsRecomendados = this._local.getBoletosRecomendados();
    this.boletos_generados = lsGenerados == null ? [] : JSON.parse(lsGenerados);
    this.boletos_recomendados =
      lsRecomendados == null ? [] : JSON.parse(lsRecomendados);
    this.UrlAnahuac = environment.Url_Avisos_Privacidad;
    this.paises = _DCompradorService.getInfopais();

    this.campusall = _DCompradorService.getInfocampus();

    this.formDatosComprador = this.fb.group({
      pais: ["", Validators.compose([Validators.required])],
      pkcanal1: ["", Validators.compose([Validators.required])],
      egresado: [
        "",
        Validators.compose([Validators.required, Validators.pattern(/^\d*$/)]),
      ],
      campus: [
        "",
        Validators.compose([Validators.pattern(/[a-z0-9A-Z????????????\s]/)]),
      ],
      nombre: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[a-zA-ZáéíóúñÑÁÉÍÓÚ\s]+$/),
        ]),
      ],
      apellidos: [
        "",
        Validators.compose([
          Validators.pattern(/^[a-zA-ZáéíóúñÑÁÉÍÓÚ\s]+$/),
        ]),
      ],
      apellido_materno: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[a-zA-ZáéíóúñÑÁÉÍÓÚ\s]+$/),
        ]),
      ],
      apellido_paterno: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[a-zA-ZáéíóúñÑÁÉÍÓÚ\s]+$/),
        ]),
      ],
      // telefono_fijo: [
      //   "",
      //   Validators.compose([Validators.required, Validators.pattern(/^\d*$/)]),
      // ],
      telefono_movil: [
        "",
        Validators.compose([Validators.required, Validators.pattern(/^\d+$/)]),
      ],
      correo_electronico: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ]),
      ],
      correo_alternativo: [
        "",
        Validators.pattern(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        ),
      ],
      correo_tarjetahabiente: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ]),
      ],
      // calle: [
      //   "",
      //   Validators.compose([
      //     Validators.required,
      //     Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚ0-9\s]+$/),
      //   ]),
      // ],
      // numero: [
      //   "",
      //   Validators.compose([
      //     Validators.required,
      //     Validators.pattern(/^[a-zA-ZáéíóúÁÉÍÓÚ0-9\s]+$/),
      //   ]),
      // ],
      codigo_postal: [
        "",
        Validators.compose([Validators.required, Validators.pattern(/^\d*$/)]),
      ],
      colonia: ["", Validators.required],
      municipio: ["", Validators.required],
      estado: ["", Validators.required],
      dia: ["", Validators.required],
      mes: ["", Validators.required],
      anio: ["", Validators.required]
    });

    this.formDatosColaborador = this.fb.group({
      correo_electronico: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ]),
      ],
    });

    this.IDColab = this._local.getPKColab();
  }

  initDias(): void {
    this.dias = Array.from({ length: 31 }, (_, i) => i + 1);
  }

  initAnios(): void {
    const currentYear = 2007; // Año máximo
    const minYear = currentYear - 100; // Últimos 100 años
    this.anios = Array.from({ length: currentYear - minYear + 1 }, (_, i) => currentYear - i);
  }
  

  ngOnInit() {
    this.initDias();
    this.initAnios();
    /* */
    $("#pais").on("change", function () {
      //console.log("cambio"); 
    });
    /* */
    this._DCompradorService.getCanales().subscribe(a => {
      this.canales = a;
      ////console.log(this.canales)
    });


    this.st.startTimer();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.limpiaInfo();
    this.getData(this.boletos_generados, this.boletos_recomendados);
    this.boletos.sort(function (a, b) {
      return a.folio - b.folio;
    });
    ////console.log(this.boletos);
    this.boletocheck = this.boletos[0];

    //VALIDAR BOLETOS
    this.boletoValida.folioBoleto = this.boletocheck.folio.toString();
    ////console.log(this.boletoValida);
    this._DCompradorService.validarBoletoPasaVoz(this.boletoValida).subscribe(a => {
      this.boletoVal = a;
      ////console.log(this.boletoVal);
      if (this.boletoVal.folioBoleto === "-1") {
        this.flagBolVal = false;
      } else {
        this.flagBolVal = true;
      }
      ////console.log(this.flagBolVal);
      ////console.log(this.IDColab);
    },
      err => {

      });

    //FIN VALIDAR BOLETO

    //crear modelo
    /// Pop up
    var popC = this._session.getPopstate();
    //this.PopupAmigable(popC);
    /// Pop up
    //Autocomplete colaboradores
    if (
      parseInt(this._session.getTipoAsignado()) == 1 &&
      this._session.getColab() != null
    ) {
      ////console.log("trae los datos del colaborador")
      this.AutocompleteColab();
      //
    }
    if (this.IDColab !== null) {
      this.validaColaborador();
    }

    /// Pop up
    /** Cambios Luis Aguilar WECODE */
    this.ChecaCampania();
    /** END Cambios Luis Aguilar WECODE */


    /*TRER DATOS EGRESADO SI ES QUE EXISTE */
    let pk_egr = sessionStorage.getItem('pk_egresado');
    let pk_egresado = 0;
    if (pk_egr) {
      pk_egresado = parseInt(pk_egr, 10);
      this._DCompradorService.GetDataEgresado(pk_egresado).subscribe(a => {
        this.InfoEgr = a;
        //console.log(this.Info);
        console.log(this.InfoEgr);
        this.Info[0].pk1 = 0;
        this.Info[0].nombre = this.InfoEgr.nombre_pila;
        this.Info[0].apellidos = this.InfoEgr.apellidos;

        let cadenaOriginal = this.InfoEgr.apellidos;
        let cadenaLimpia = cadenaOriginal.trim();
        let apellidos = cadenaLimpia.split(" ");

        //console.log(apellidos);


        this.Info[0].apellido_paterno = apellidos[0];
        this.Info[0].apellido_materno = apellidos[1];

        this.Info[0].telefono_fijo = this.InfoEgr.telefono_celular;
        this.Info[0].telefono_movil = this.InfoEgr.telefono_celular;
        this.Info[0].correo_electronico = this.InfoEgr.email;
        this.Info[0].correo_alternativo = this.InfoEgr.email;
        this.Info[0].correo_tarjetahabiente = this.InfoEgr.email;
        //this.Info[0].pais = this.InfoEgr.pais;
        //console.log(this.InfoEgr.cp.toString().padStart(5, '0'));
        this.Info[0].codigo_postal = this.InfoEgr.cp.toString().padStart(5, '0');
        this.getInfoCP(this.Info[0].codigo_postal);
        this.Info[0].colonia = this.InfoEgr.colonia;
        this.Info[0].estado = this.InfoEgr.estado_provincia;
        this.Info[0].municipio = this.InfoEgr.alcaldia_municipo;
        this.Info[0].egresado = "1";
        this.flagPlaceCampus = true;
        this.Info[0].campus = this.InfoEgr.campus;

        this.aceptar_terminos = true;
        this.rotular_todos = true;
        console.log(this.Info[0]);
        sessionStorage.removeItem('pk_egresado');
      },
        err => {

        });
    } else {
      // console.log("no existe");
    }


  }

  ngAfterViewInit() {
    tab = document.getElementById("tabla");
    img = document.getElementById("Izq");
    imge = document.getElementById("Der");
    img.hidden = true;
    var tabc = document.getElementById("tab");
    ////console.log(tabc.clientWidth);

    if (tab.clientWidth >= tabc.clientWidth) {
      imge.hidden = true;
    }
    nombre = document.getElementById("nombre") as HTMLInputElement;
    campus = document.getElementById("campus") as HTMLSelectElement;
    egresado = document.getElementById("egresado") as HTMLInputElement;

    apellido_paterno = document.getElementById("apellido_paterno") as HTMLInputElement;
    apellido_materno = document.getElementById("apellido_materno") as HTMLInputElement;
    apellidos = document.getElementById("apellidos") as HTMLInputElement;



    //	this.Bloqueanombre(nombre,apellido_paterno,apellido_materno);
  }

  private Anterior() {
    if (this.formDatosComprador.valid) {
      this.validaDatos[this.cont] = "valido";
      this.cont--;
      this.Info[0] = this.obtenerDatos[this.cont];
      this.getInfoCP(this.Info[0].codigo_postal);
      this.boletocheck = this.boletos[this.cont];
      //this.Bloqueanombre(nombre,apellido_paterno,apellido_materno);
      //VALIDAR BOLETOS
      this.boletoValida.folioBoleto = this.boletocheck.folio.toString();
      ////console.log(this.boletoValida);
      this._DCompradorService.validarBoletoPasaVoz(this.boletoValida).subscribe(a => {
        this.boletoVal = a;
        ////console.log(this.boletoVal);
        if (this.boletoVal.folioBoleto === "-1") {
          this.flagBolVal = false;
        } else {
          this.flagBolVal = true;
        }
        ////console.log(this.flagBolVal);
        ////console.log(this.IDColab);
      },
        err => {

        });

      //FIN VALIDAR BOLETO
    } else {
      this.valida = true;
      this.mensajeError = "Favor de llenar todos los campos obligatorios";
    }
  }

  private Siguiente() {
    if (this.formDatosComprador.valid) {
      this.AlmacenaDatos();

      this.validaDatos[this.cont] = "valido";
      this.cont++;
      //this.infoDatosChanged();
      this.Info[0] = this.obtenerDatos[this.cont];
      this.Colonias = [];
      if (this.obtenerDatos[this.cont].codigo_postal.length > 3) {
        this.getInfoCP(this.Info[0].codigo_postal);
      }
      this.boletocheck = this.boletos[this.cont];
      //VALIDAR BOLETOS
      this.boletoValida.folioBoleto = this.boletocheck.folio.toString();
      ////console.log(this.boletoValida);
      this._DCompradorService.validarBoletoPasaVoz(this.boletoValida).subscribe(a => {
        this.boletoVal = a;
        // //console.log(this.boletoVal);
        if (this.boletoVal.folioBoleto === "-1") {
          this.flagBolVal = false;
        } else {
          this.flagBolVal = true;
        }
        ////console.log(this.flagBolVal);
        ////console.log(this.IDColab);
      },
        err => {

        });

      //FIN VALIDAR BOLETO
    } else {
      this.valida = true;
      this.mensajeError = "Favor de llenar todos los campos obligatorios";
    }
  }

  private ValidaPais() {

    if (this.Info[0].pais == "484") {

      this.ocultar_cp = true;
      this.Info[0].codigo_postal = "";
      this.formDatosComprador.controls["codigo_postal"].enable();
      this.formDatosComprador.controls["municipio"].disable();
      //this.formDatosComprador.controls["colonia"].disable(); 
      this.formDatosComprador.controls["estado"].disable();
      if (this.Colonias.length == 0) {
        this.formDatosComprador.controls["colonia"].disable();
      }
      if (this.Colonias.length > 0) {
        this.formDatosComprador.controls["colonia"].enable();
      }
    } else {

      this.ocultar_cp = false;
      this.Info[0].codigo_postal = "00000";
      this.formDatosComprador.controls["colonia"].enable();
      this.formDatosComprador.controls["municipio"].enable();
      this.formDatosComprador.controls["estado"].enable();

    }
    this.Info[0].pais = "484";
    ////console.log(this.Info[0])
  }


  public ValidaApellidos() {
    var ap1 = <HTMLInputElement>document.getElementById('apellido_paterno');
    var ap2 = <HTMLInputElement>document.getElementById('apellido_materno');
    var ap3 = <HTMLInputElement>document.getElementById('apellidos');
    ap3.value = ap1.value + " " + ap2.value;
    this.formDatosComprador.controls['apellidos'].setValue(ap3.value);
  }

  public ValidaCampus() {
    var camslct = document.getElementById('campusslct') as HTMLSelectElement;
    var caminp = <HTMLInputElement>document.getElementById('campus');
    caminp.value = camslct.value;
    this.flagPlaceCampus = true;
    //campus

    $('#campusslct').removeClass('inv');
    $('#campusslct').removeClass('is-invalid');
    $('#campusslct').addClass('is-valid');
    ////console.log( this.flagPlaceCampus);
  }

  public ValidaEgresado() {
    var cod = document.getElementById('egresadoslct') as HTMLSelectElement;
    var coe = <HTMLInputElement>document.getElementById('egresadoinput') as HTMLInputElement;
    var di = document.getElementById('campusdiv') as HTMLDivElement;
    if (cod.value == "0") {
      coe.value = "0";
    } else {

      coe.value = "1";
    }

    let combo = document.getElementById("egresadoslct") as HTMLSelectElement;
    let selected = combo.options[combo.selectedIndex].text;

    if (selected === "Si") {
      this.flagCampus = true;
    } else {
      this.flagCampus = false;
    }


    ////console.log(this.flagCampus);
  }


  private AlmacenaDatos() {
    let municipio = (<HTMLInputElement>document.getElementById('municipio') as HTMLInputElement).value;
    let estado = (<HTMLInputElement>document.getElementById('estado') as HTMLInputElement).value;
    this.obtenerDatos[this.cont].colonia = this.Info[0].colonia;
    this.obtenerDatos[this.cont].codigo_postal = this.Info[0].codigo_postal;
    this.obtenerDatos[this.cont].pais = this.Info[0].pais;
    this.obtenerDatos[this.cont].estado = estado;
    this.obtenerDatos[this.cont].municipio = municipio
    this.obtenerDatos[this.cont].nombre = this.Info[0].nombre;
    this.obtenerDatos[this.cont].apellidos = this.Info[0].apellidos;
    this.obtenerDatos[this.cont].apellido_paterno = this.Info[0].apellido_paterno;
    this.obtenerDatos[this.cont].apellido_materno = this.Info[0].apellido_materno;
    this.obtenerDatos[this.cont].telefono_fijo = this.Info[0].telefono_fijo;
    this.obtenerDatos[this.cont].telefono_movil = this.Info[0].telefono_movil;
    this.obtenerDatos[this.cont].correo_electronico = this.Info[0].correo_electronico;
    this.obtenerDatos[this.cont].correo_alternativo = this.Info[0].correo_alternativo;
    this.obtenerDatos[this.cont].correo_tarjetahabiente = this.Info[0].correo_tarjetahabiente;
    this.obtenerDatos[this.cont].calle = this.Info[0].calle;
    this.obtenerDatos[this.cont].numero = this.Info[0].numero;
    this.obtenerDatos[this.cont].egresado = this.Info[0].egresado;
    this.obtenerDatos[this.cont].pkcanal = this.Info[0].pkcanal;

    this.obtenerDatos[this.cont].dia = this.Info[0].dia;
    this.obtenerDatos[this.cont].mes = this.Info[0].mes;
    this.obtenerDatos[this.cont].anio = this.Info[0].anio;
  

    if (this.Info[0].pkcanal == 14 || this.Info[0].pkcanal == 15) {
      this.obtenerDatos[this.cont].especifica_canal = this.Info[0].especifica_canal;
    } else {
      this.obtenerDatos[this.cont].especifica_canal = "";
    }


    if (this.flagCampus == false) {
      this.obtenerDatos[this.cont].campus = "";
    } else {
      this.obtenerDatos[this.cont].campus = this.Info[0].campus;
    }

    ////console.log(this.obtenerDatos);
  }

  getData(
    boletos_generados: Array<Boleto>,
    boletos_recomendados: Array<Boleto>
  ) {
    ////console.log(boletos_generados);
    ////console.log(boletos_recomendados);

    if (boletos_recomendados !== []) {
      for (var i = 0; i <= boletos_recomendados.length - 1; i++) {
        this.total_compra = this.total_compra + boletos_recomendados[i].costo;
        this.boletos.push(boletos_recomendados[i]);
        ///Autocomplete compradores boletos de la suerte
        if (this.boletos[i].compradorFront != null) {
          this.boletos[i].comprador = this.boletos[i].compradorFront;
          this.obtenerDatos[i].nombre = this.boletos[i].compradorFront.nombre;
          this.obtenerDatos[i].apellidos =
            this.boletos[i].compradorFront.apellidos;
          this.obtenerDatos[i].correo_electronico =
            this.boletos[i].compradorFront.correo_electronico;

          this.Info[i].nombre = this.boletos[i].compradorFront.nombre;
          this.Info[i].apellidos = this.boletos[i].compradorFront.apellidos;
          this.Info[i].correo_electronico =
            this.boletos[i].compradorFront.correo_electronico;


          this.Info[0].telefono_movil =
            this.boletos[i].compradorFront.telefono_movil;
          if (this.boletos[i].compradorFront.direccion != null) {
            this.Info[0].telefono_fijo =
              this.boletos[i].compradorFront.direccion.telefono;
            this.Info[0].calle = this.boletos[i].compradorFront.direccion.calle;
            if (this.boletos[i].compradorFront.direccion.codigo_postal != 0) {
              this.Info[0].codigo_postal =
                this.boletos[i].compradorFront.direccion.codigo_postal;
              this.getInfoCP(this.Info[0].codigo_postal);
            }
            if (!this.boletos[i].compradorFront.pais) {
              this.Info[0].codigo_postal =
                this.boletos[i].compradorFront.direccion.codigo_postal;
            }
          }
        }
      }
    }

    if (boletos_generados !== []) {
      for (var i = 0; i <= boletos_generados.length - 1; i++) {
        //this.total_compra = this.total_compra + boletos_generados[i].costo;
        this.boletos.push(boletos_generados[i]);
      }
    }
  }

  infoCPChanged() {
    this.Info[0].estado = "";
    this.Info[0].municipio = "";
    this.Info[0].colonia = "";

    if ($("input#codigo_postal").val().length < 5) {
      this.Info[0].estado = "";
      this.Info[0].municipio = "";
      this.Info[0].colonia = "";
      $('#municipio').addClass('is-invalid');
      $('#municipio').removeClass('is-valid');
      $('#estado').addClass('is-invalid');
      $('#estado').removeClass('is-valid');
      this.flagCP = false;
      this.Colonias = [];
    } else {
      this.flagCP = true;
    }
  }

  infoDatosChanged() {
    this.Info[0].nombre = "";
    this.Info[0].apellidos = "";
    this.Info[0].telefono_fijo = "";
    this.Info[0].telefono_movil = "";
    this.Info[0].correo_electronico = "";
    this.Info[0].correo_alternativo = "";
    this.Info[0].correo_tarjetahabiente = "";
    this.Info[0].calle = "";
    this.Info[0].numero = "";
    this.Info[0].estado = "";
    this.Info[0].municipio = "";
    this.Info[0].colonia = "";
    this.Info[0].nombre = "";
    this.Info[0].codigo_postal = "";
    this.Info[0].campus = "";
    this.Info[0].egresado = "";
    this.Info[0].apellido_materno = "";
    this.Info[0].apellido_paterno = "";

  }

  getInfoCP(codigo_postal: string) {
    //console.log("Entra getInfoCP");
    if (codigo_postal.length < 5) {
      ////console.log(codigo_postal);
      for (var i = codigo_postal.length; i < 5; i++) {
        this.Info[this.cont].codigo_postal =
          "0" + this.Info[this.cont].codigo_postal;
        ////console.log(codigo_postal.length);
        ////console.log(this.Info[this.cont].codigo_postal);
      }
      codigo_postal = this.Info[this.cont].codigo_postal;
      ////console.log(codigo_postal);
    }
    this._DCompradorService.getInfoCP(codigo_postal).subscribe(
      (res) => {
        ////console.log("200 COD POSTAL");
        ////console.log(res);

        if (res.length == 0) {
          //Estado
          $('#estado').addClass('is-invalid');
          $('#estado').removeClass('is-valid');
          //Municipio
          $('#municipio').addClass('is-invalid');
          $('#municipio').removeClass('is-valid');
          this.typeAlert = "error";
          this.messageAlert =
            "No se encontraron datos para el código postal proporcionado";
        } else {
          this.messageAlert = "";
          //Estado
          $('#estado').removeClass('is-invalid');
          $('#estado').addClass('is-valid');
          //Municipio
          $('#municipio').removeClass('is-invalid');
          $('#municipio').addClass('is-valid');
          this.Colonias = [];
          this.Info[this.cont].colonia = "";
          var Datos = res;
          //console.dir(Datos);
          if (Datos.length == 1) {
            this.Info[0].colonia = Datos[0].colonia;
          } else {
            Datos.forEach(
              function (item) {
                this.Colonias.push({ colonia: item.colonia });
              }.bind(this)
            );
            this.typeAlert = "success";
            this.messageAlert = "Favor seleccionar colonia.";
          }

          this.Info[0].estado = Datos[0].estado;
          this.Info[0].municipio = Datos[0].municipio;

          //Toast.makeText("Estado y municipio cargado.", "short").show();

          if (this.Info[0].pais != "484") {
            this.formDatosComprador.controls["colonia"].enable();
          }

          if (this.Info[0].pais == "484" && this.Colonias.length > 0) {
            this.formDatosComprador.controls["colonia"].enable();
          }
          if (this.Info[0].pais == "484" && this.Colonias.length == 0) {
            this.formDatosComprador.controls["colonia"].disable();
            $('#colonia').addClass('is-valid');
            $('#colonia').removeClass('is-invalid');
          }
        }
      },
      (error) => {
        ////console.log("500 COD POSTAL");
        ////console.log(error);
        this.typeAlert = "error";
        this.messageAlert =
          "No se encontraron datos para el código postal proporcionado.";
      }
    );


  }

  efectuarPago() {
    let flagPlcCampus = false;
    if (this.flagCampus == false) {
      flagPlcCampus = true;
    } else if (this.flagCampus && this.flagPlaceCampus) {
      flagPlcCampus = true;
    } else {
      flagPlcCampus = false;
    }

    let flagCanal = false;


    if (this.Info[0].pkcanal == 14 || this.Info[0].pkcanal == 15) {
      ////console.log( $("input#especifica_canal").val());
      if (($("input#especifica_canal").val().length != 0) && !this.especificaCanalInvalid) {
        flagCanal = true;
      } else if ($("input#especifica_canal").val() == "") {
        $("input#especifica_canal").addClass('is-invalid');
        $("input#especifica_canal").removeClass('is-valid');
        flagCanal = false;
      }
    }

    if (this.Info[0].pkcanal != 14 && this.Info[0].pkcanal != 15) {
      flagCanal = true;
    }

    ////console.log(flagCanal);

    // si aceptar es true
    ////console.log(this.obtenerDatos);
    if (this.aceptar_terminos && flagPlcCampus) {
      ////console.log(flagCanal);
      if (this.formDatosComprador.valid && flagCanal) {
        this.mensajeError = "";
        ////console.log("entro");
        ////////////////////Nuevo codigo////////////////
        // if(!this.rotular_todos){
        // 	if(this.cont< this.boletos.length-1){

        // 		this.AlmacenaDatos();
        // 		this.infoDatosChanged();
        // 		return 0;
        // 	}
        // }

        //GUARDA LEAD
        this.RegistroDatosCampanias();
        this.AlmacenaDatos();
        this.rotulaBoleto();

        for (var i = 0; i < this.boletos.length; i++) {
          if (this.validaDatos[i] != "valido") {
            this.mensajeError = "Favor de llenar todos los boletos";
            //this.cont--;
            ////console.log(this.validaDatos);
            return 0;
          }
        }

        ////////////////////nuevo codigo //////////////

        ////console.log('Boletos a pagar', this.boletos);

        /// Asigna Colaborador Voluntario

        if (!this.colaborador_voluntarioC) {
          if (this.selecciona_colaboradorC) {
            if (this.resultIDcolab != undefined) {
              this._local.setPKColab(this.resultIDcolab);
              if (this._local.getMecanismoColabExt() == null) {
                this._local.setMecanismoColabExt("VentaPorI");
              }
            } else {
              if (this.resultIDcolab == null) {
                this._local.removePKColab();
                this._local.removeMecanismoColabExt();
              }
            }
          } else {
            if (this.IDColab) {
              this._local.setPKColab("null");
              this._local.removePKColab();
              this._local.removeMecanismoColabExt();
            }
          }
        } else {
          if (this._local.getPKColab() != null) {
            this._local.setPKColab(this.resultIDcolab);
            if (this._local.getMecanismoColabExt() == null) {
              this._local.setMecanismoColabExt("VentaPorI");
            }
          }
        }

        ////console.log("pk colab externo:");
        ////console.log(this._local.getPKColab());
        ////console.log("mecanismo:");
        ////console.log(this._local.getMecanismoColabExt());
        // if(info_bol.length > 0){
        this._local.setBoletosApartados(this.boletos);
        //	this._local.setBoletosApartados(info_bol);
        //console.log(this.boletos);
        //LLAMAR API PARA INSERTAR EL SIGUIMIENTO
        sessionStorage.setItem('paginaclick', '/datos-comprador');
        let respuesta: any;
        this._seguimiento.addSeguimiento(this.boletos).subscribe(a => {
          // console.log(a);
          respuesta = a;
          const pk_seguimiento = respuesta.pk1;
          //console.log(pk_seguimiento);
          sessionStorage.setItem('pk_seguimiento', pk_seguimiento);
        },
          err => {

          });
        this.router.navigate(["forma-pago"]);// descomentar
        // }else {
        // 	this.mensajeError = "Favor de seleccionar al menos un boleto"
        // }
      } else {
        this.valida = true;
        this.mensajeError = "Favor de llenar todos los campos obligatorios";
        //LLAMAR MODAL ERROR INPUTS
        $("input.ng-invalid, select.ng-invalid").addClass("is-invalid");
      }
    } else {
      //LLAMAR MODAL ERROR INPUTS
      this.mensajeError = "Favor de aceptar los términos y condiciones";
      $("input.ng-invalid, select.ng-invalid").addClass("is-invalid");
    }
  }

  // -- Limpia Info --
  public limpiaInfo() {
    var cantboletos = 0;
    cantboletos = cantboletos + this.boletos_generados.length;
    cantboletos = cantboletos + this.boletos_recomendados.length;
    for (var i = 0; i < cantboletos; i++) {
      this.Info[i] = {
        pk1: 0,
        nombre: "",
        apellidos: "",
        apellido_materno: "",
        apellido_paterno: "",
        telefono_fijo: "",
        telefono_movil: "",
        correo_electronico: "",
        correo_alternativo: "",
        correo_tarjetahabiente: "",
        calle: "",
        numero: "",
        pais: "",
        codigo_postal: "",
        colonia: "",
        estado: "",
        municipio: "",
        campus: "",
        egresado: "",
        pkcanal: null,
        especifica_canal: "",
        dia:  "",
        mes:  "",
        anio: "",
      };
      this.obtenerDatos[i] = {
        pk1: 0,
        nombre: "",
        apellidos: "",
        apellido_paterno: "",
        apellido_materno: "",
        telefono_fijo: "",
        telefono_movil: "",
        correo_electronico: "",
        correo_alternativo: "",
        correo_tarjetahabiente: "",
        calle: "",
        numero: "",
        codigo_postal: "",
        pais: "",
        colonia: "",
        municipio: "",
        estado: "",
        campus: "",
        egresado: "",
        pkcanal: null,
        especifica_canal: "",
        dia:  "",
        mes:  "",
        anio: "",
      };
      this.validaDatos[i] = "";
    }
  }
  // -- Limpia Info --

  //// ------------ POPUP------------
  /*
  PopupAmigable(popC) {
    var PopAct = document.getElementById("popupamigable");
    if (popC == 0) {
      setTimeout(() => {
        document.addEventListener("mouseout", (event) => {
          if (event.relatedTarget === null && popC == 0) {
            PopAct.hidden = false;
            popC = 2;
            this._session.setPopstate(popC);
          }
        });
      }, 5);
    } else {
      PopAct.hidden = true;
    }
  }*/
  //// ------------ POPUP------------

  scroll(mov) {
    if (mov == 1) {
      tab.scrollLeft -= 20;
    } else {
      tab.scrollLeft += 20;
    }
    this.hidscroll();
  }
  hidscroll() {
    var max = tab.scrollWidth - tab.clientWidth;
    if (tab.scrollLeft == 0) {
      img.hidden = true;
    } else {
      img.hidden = false;
    }
    if (tab.scrollLeft == max) {
      imge.hidden = true;
    } else {
      imge.hidden = false;
    }
  }

  rotulaBoleto() {
    if (this.rotular_todos) {
      for (var i = 0; i < this.boletos.length; i++) {
        this.boletos[i].comprador = this.obtenerDatos[0];
        this.validaDatos[i] = "valido";
      }
    } else {
      for (var i = 0; i < this.boletos.length; i++) {
        this.boletos[i].comprador = this.obtenerDatos[i];
      }
      this.validaDatos[this.cont] = "valido";
    }
  }

  public onLoaded(contador) {
    ////console.log("---Contador---");
    ////console.log(contador);
  }

  private CambiaBoletoCheck(index) {
    if (this.formDatosComprador.valid) {
      this.AlmacenaDatos();
      this.validaDatos[this.cont] = "valido";
      this.cont = index;
      this.Info[0] = this.obtenerDatos[this.cont];
      this.Colonias = [];
      if (this.obtenerDatos[this.cont].codigo_postal.length > 3) {
        this.getInfoCP(this.Info[0].codigo_postal);
      }
      this.boletocheck = this.boletos[this.cont];
    } else {
      this.valida = true;
      this.mensajeError = "Favor de llenar todos los campos obligatorios";
    }
  }

  private AutocompleteColab() {
    this._DCompradorService
      .getInfoColab(this._session.getColab())
      .subscribe((res) => {
        ////console.log(res);
        this.Info[0].nombre = res.nombre;
        this.Info[0].apellidos = res.apellidos;
        this.Info[0].apellido_materno = res.apellido_materno;
        this.Info[0].apellido_paterno = res.apellido_paterno;
        this.Info[0].telefono_movil = res.telefono_movil;
        this.Info[0].correo_electronico = res.correo_electronico;
        this.Info[0].correo_alternativo = res.correo_alternativo;
        this.Info[0].correo_tarjetahabiente = res.correo_tarjetahabiente;
        this.Info[0].telefono_fijo = res.direccion.telefono;
        this.Info[0].calle = res.direccion.calle;
        this.Info[0].numero = res.direccion.numero;
        this.Info[0].codigo_postal = res.direccion.codigo_postal;
        this.getInfoCP(this.Info[0].codigo_postal);
        this.Info[0].colonia = res.direccion.colonia;
        this.Info[0].campus = res.campus;
        this.Info[0].egresado = res.egresado;
      });
  }

  // private Bloqueanombre(nombre,apellido_paterno,apellido_materno){

  // 	if(this.boletos[this.cont].compradorFront != null){
  // 		nombre.disabled = true;
  // 		apellido_paterno.disabled = true;

  // 		if(this.boletos[this.cont].compradorFront.apellido_materno != null){
  // 			apellido_materno.disabled = true;
  // 		}

  // 	}else{
  // 		nombre.disabled = false;
  // 		apellido_paterno.disabled = false;
  // 		apellido_materno.disabled = false;
  // 	}

  // }

  private validaColaborador() {
    ////console.log(this.IDColab);
    var Datos = {
      Nombre: null,
      IDColaborador: this.IDColab, //IDColab
    };
    this.myGetService
      .postData(Datos, "api/Colaborador/Externos/GetNombre")
      .subscribe((res) => {
        ////console.log(res);
        var result = res.json();
        this.nombreColab = result.Nombre;
        this.resultIDcolab = result.IDColaborador;
        this.colaborador_voluntarioC = true;
      });
  }

  private GetNombreColab() {
    if (this.intentosColab > 0) {
      if (this.correocolaborador != null) {
        var Datos = {
          Nombre: this.correocolaborador, //Correo Colab
          IDColaborador: null,
        };
        this.intentosColab--;
        this.myGetService
          .postData(Datos, "api/Colaborador/Externos/GetNombre")
          .subscribe(
            (res) => {
              ////console.log(res);
              var result = res.json();
              this.nombreColab2 = result.Nombre;
              this.resultIDcolab = result.IDColaborador;
              this.messageAlert1 =
                "Si no es la persona correcta, por favor, verifica el e-mail.";
              if (this.intentosColab == 0) {
                var input = document.getElementById(
                  "inp_colab"
                ) as HTMLInputElement;
                input.readOnly = true;
              }
            },
            (err) => {
              if (this.intentosColab > 0) {
                this.nombreColab2 = "Colaborador voluntario no encontrado";
                this.messageAlert1 =
                  "Por favor, verifique el e-mail. Cuenta con " +
                  this.intentosColab +
                  " intentos más de busqueda, después será bloqueada.";
                ////console.log(this.resultIDcolab);
                this.nombreColab2 = null;
                this.resultIDcolab = null;
              }
              if (this.intentosColab == 0) {
                this.messageAlert1 =
                  "Has llegado al limite de 3 intentos. Ponten en contacto con nosotros al correo: " +
                  environment.correo_pasalavoz;
                var input = document.getElementById(
                  "inp_colab"
                ) as HTMLInputElement;
                input.readOnly = true;
                //this.selecciona_colaboradorC = false;
                var cbox = document.getElementById(
                  "checkbox_seleccionaC"
                ) as HTMLInputElement;
                //cbox.checked = false;
                //cbox.readOnly = true;
                this.nombreColab2 = null;
                this.resultIDcolab = null;
                ////console.log(this.resultIDcolab);
              }
            }
          );
      }
    }
  }

  //change select
  onChange(deviceValue) {
    this.flagSelect = true;
  }

  validaCanal(event: any) {
    const inputElement = event.target;
    const pattern = /^[a-zA-ZáéíóúÁÉÍÓÚ0-9\s\-\/\.]+$/;

    if (inputElement.value.length !== 0 && pattern.test(inputElement.value)) {
      inputElement.classList.add('is-valid');
      inputElement.classList.remove('is-invalid');
      this.especificaCanalInvalid = false;
      this.Info[0].especifica_canal = inputElement.value;
    } else {
      inputElement.classList.add('is-invalid');
      inputElement.classList.remove('is-valid');
      this.especificaCanalInvalid = true;
    }
  }
  validaDir() {
    //console.log("entre");
    //console.log( this.Info[0]);

    this.Info[0].colonia = null;
    $('#colonia').val("");
    if ($('#colonia').hasClass('is-valid')) { $('#colonia').removeClass('is-valid'); }
    //console.log("colonia " + this.Info[0].colonia);

    this.Info[0].municipio = null;
    $('#municipio').val("");
    if ($('#municipio').hasClass('is-valid')) { $('#municipio').removeClass('is-valid'); }
    //console.log("municipio " + this.Info[0].municipio);

    this.Info[0].estado = null;
    $('#estado').val("");
    if ($('#estado').hasClass('is-valid')) { $('#estado').removeClass('is-valid'); }
    //console.log("estado " + this.Info[0].municipio);

  }

  validaInfo() {

    if (
      $('#colonia').hasClass("is-invalid") || $('#colonia').val() == "" ||
      $('#municipio').hasClass("is-invalid") || $('#colonia').val() == "" ||
      $('#estado').hasClass("is-invalid") || $('#estado').val() == ""

    ) {
      alert("Favor de completar datos");
    } else {
      this.efectuarPago();
    }
  }

  //// ------------ POPUP------------
  /* VALIDAR BOLETOS */
  validaBoletoVxI() {
    //console.log( "validaBoleto");1
    let boletoTem = this.boletos.map(b => b.folio).join(',');
    //console.log(boletoTem);
    let spliData = boletoTem.split(",");

    //console.log(spliData);
    for (let i = 0; i < spliData.length; i++) {
      let boletoValida: BoletoValidar = new BoletoValidar();
      boletoValida.folioBoleto = spliData[i];
      //console.log(boletoValida);
      this._DCompradorService.validarBoletoPasaVoz(boletoValida).subscribe(a => {
        //console.log(a);
        let boletoTem: any = a;
        //console.log(boletoTem.folioBoleto);
        if (boletoTem.folioBoleto === "-1") {
          //console.log("aqui entre");
          this.flagVentaXInt = false;
          localStorage.setItem("leads", 'false');
        } else {
          this.flagVentaXInt = true;
          localStorage.setItem("leads", 'true');
        }
      });
    }

    //console.log( localStorage.getItem("leads"));
  }
  /** CAMBIOS LUIS AGUILAR WECODE */
  //Funcion para revisar si se registraron datos de redirecciones por campaña en la página principal
  private ChecaCampania() {
    //console.log("campnia");
    this.validaBoletoVxI();
    setTimeout(() => {
      let campaingData = this._local.getCampaingnData();
      if (campaingData && campaingData['pk']) {//ya se registro el correo, solo se actualizan los boletos
        campaingData['boletos_seleccionados'] = this.boletos.map(b => b.folio).join(',');
        //comprobar que los boletos sean solo venta por internet

        /* */
        if (this.flagVentaXInt) {

          this.myGetService.postData(campaingData, 'api/Leads/ModificaLeads')
            .subscribe((res) => {
              console.log(res);
              console.log('Info Registro de campaña', campaingData);
              this._local.setCampaingnData(campaingData);
            });
        }


      } else {//no han registrado el correo mostrar el popup
        //console.log(this.flagVentaXInt);
        if (campaingData && this.flagVentaXInt) {//Revisar si hay datos de campaña
          //console.log("else entro");

          //this.formDatosCompradorCampania = this.fb.group({
          //correo: [ '', Validators.compose([ Validators.required, Validators.pattern( /^[!#$%&'*+-=?^_`{|}~a-z0-9-]+(\.[!#$%&'*+-=?^_`{|}~a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/),]),],
          //});
          /* abrit modal modalCompania */
          //var element = document.getElementById("modalCompania");
          //element.classList.add("show");
          //element.classList.add("shwModalCompania");
          this.ShowPopCampania = true;
          localStorage.setItem("ShowPopCampania2", 'true');
        }
      }
    }, 300);

  }

  RegistroDatosCampanias() {
    this.validaBoletoVxI();
    setTimeout(() => {

      if (this.flagVentaXInt) {
        let campaingData = this._local.getCampaingnData();

        let registro: Object = Object.assign({}, '', {
          site_source_name: campaingData.site_source_name ? campaingData.site_source_name : "N/A",
          placement: campaingData.placement ? campaingData.placement : "N/A",
          utm_campaign: campaingData.utm_campaign ? campaingData.utm_campaign : "N/A",
          utm_content: campaingData.utm_content ? campaingData.utm_content : "N/A",
          boletos_seleccionados: this.boletos.map(b => b.folio).join(','),
          tipo_comprador: 'Externo',
          sorteo: 8,
          anio: 2023,
          correo: this.Info[0].correo_electronico
        });
        //console.log('RegistroDatosCampanias registro', registro);
        this.myGetService.postData(registro, 'api/Leads/RegistroLeads')
          .subscribe((res) => {
            //console.log(res);
            let bodyData = JSON.parse(res['_body']);
            registro['pk'] = Number(bodyData['pkCreado']);
            //console.log('Info Registro de campaña', registro);
            /* cerrar modal */
            // var element = document.getElementById("modalCompania");
            // element.classList.remove("show");
            // element.classList.remove("shwModalCompania");
            this._local.setCampaingnData(registro);
          });
        this.ShowPopCampania = false;
      }
    }, 300);
  }

  ClosePopCampanias() {
    /*let registro: Object = Object.assign({}, this.InfoCompradorCampania, {
      utm_campaign: this.campaingData.utm_campaign,
      utm_content: this.campaingData.utm_content,
      utm_placement: this.campaingData.utm_placement,
      campaign_name: this.campaingData.campaign_name,
      site_source_name: this.campaingData.site_source_name,
      placement: this.campaingData.placement,
      completo: false,
      });
      //console.log('Info Registro de campaña incompleta', registro);
      localStorage.setItem('campaingData', JSON.stringify(registro));*/
    this._local.deleteCampaingnData();
    this.ShowPopCampania = false;
    localStorage.setItem("ShowPopCampania2", 'false');
  }

  /** END CAMBIOS LUIS AGUILAR WECODE */
}

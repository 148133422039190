export const environment = {
  production: false,
  PaypalAmbiente: 'https://www.paypal.com/sdk/js?client-id=AbWn8j1aN8mxWxdvFKaTBcYwdfmmWTA9oQPpUJNj3zsEXA1GQZjRS1bSIO_bekrK4jfhh83UgoeAcBMM&currency=MXN&vault=true',//cambiar cuando se entreguen nuevas credenciales
  UrlBase: 'https://qaserviciosanivapp.sorteoanahuac.mx/',
  Url_Avisos_Privacidad: 'https://qasitio.sorteoanahuac.mx/aviso-de-privacidad',
  Url_reglamento: 'https://qasitio.sorteoanahuac.mx/reglamento-becario',
  Url_terminos: 'https://qasitio.sorteoanahuac.mx/terminos-y-condiciones',
  correo_pasalavoz: 'voz.sorteo@anahuac.com',
  Urlsitio: 'https://qasitio.sorteoanahuac.mx/',
  urlCompartirBoletos: 'https://qapagoaniv.sorteoanahuac.mx/boletos-colaborador/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
